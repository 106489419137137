export default{
    "wealthNeedNextLevel": "Experience can be upgraded to the next level.",
    "pkTouramentPro": "PK Tourament Pro",
    "pkTop1": "Top1",
    "pkTop2": "Top2",
    "pkTop3": "Top3",
    "pk1000Usd": "1000 USD",
    "pk500Usd": "500 USD",
    "pk300Usd": "300 USD",
    "pkRewards": "Rewards for each Qualifying Round",
    "pkRewards2": "Rewards for each Promotion Round",
    "pk50kCoins": "50k Coins Target",
    "pk100kCoins": "100k Coins Target",
    "pkNote": "Note: for qualifying round and promotion round rewards, if the diamonds spent in the specific PK is less than the reward amount. The reward will not be sent but the PK result is still counted",
    "pk200Usd": "200 USD",
    "pk30Day": "*30 Days",
    "pk15Day": "*15 Days",
    "pk7Day": "*7 Days",
    "pkNote2": "Note：Support in all PK Rounds is calculated. Lucky gifts are counted 10% Treasure Boxes are counted based on the gift won amount. ",
    "pkTourament": "Tourament",
    "pkToday": "Today Schedule",
    "pkSupporter": "Supporter",
    "pkQualifying": "Qualifying Round",
    "pkPromotion": "Promotion Round",
    "pkSummit": "Summit Series",
    "pk16Finals": "1/16-Finals",
    "pk8Finals": "1/8-Finals",
    "pkQuater": "Quater-Finals",
    "pkSemi": "Semi-Finals",
    "pkFinal": "Final",
    "pk3rd": "3rd Place",
    "dreamMidsummer": "Midsummer Night's Dream",
    "dreamSend": "Send exclusive gifts to seal your midsummer love",
    "dreamRank": "Sending the following event gifts during the event. The higher the ranking, the richer the prizes. Come and participate to get generous rewards!",
    "dreamPreview": "Rewards Preview",
    "dreamSupporter": "Supporter",
    "dreamHost": "Host",
    "dreamTop1": "Top1",
    "dreamTop2": "Top2",
    "dreamTop3": "Top3",
    "dreamTop4_10": "Top4-10",
    "dreamRankings": "Rankings",
    "dreamRose": "Rose Throne",
    "dreamRoseBasket": "Rose Basket",
    "dreamButterfly": "Butterfly Wonderland",
    "dreamLovers": "Everlasting Love",
    "dreamFlower": "How Much Love",
    "dream15d": "*15d",
    "dreamCentury": "Glorious Century",
    "dream7": "*7",
    "dream7d": "*7d",
    "dreamHeart": "Heart Flying",
    "dreamSummerLove": "Summer Love",
    "dreamFancy": "Fancy Unicorn",
    "dreamRules": "Event Rules",
    "dreamTime": "Event time",
    "dreamPlay": "How to play",
    "dreamUsersTop": "During the event, via sending specific gifts, users are ranked based on the number of diamonds given and coins received. Users with top rankings can receive rewards.",
    "dreamRewards": "Rewards for Rose Basket and Butterfly Wonderland Ranking",
    "dreamFrame": "Rose Throne Frame",
    "dreamCenturyGift": "Glorious Century Gift",
    "dreamEntrance": "Heart Flying Entrance",
    "dream5": "*5",
    "dream5d": "*5d",
    "dream3": "*3",
    "dream3d": "*3d",
    "dreamDiamonds": "Diamonds",
    "dream25000": "*25000",
    "dreamVerification": "Summer Love Verification",
    "dreamUnicorn": "Fancy Unicorn",
    "dreamEverlasting": "Rewards for Everlasting Love and How Much Love Rank",
    "dreamCoins": "Coins",
    "dream100000": "*100000",
    "dream50000": "*50000",
    "dream5000": "*5000",
    "dreamHostBottom": "Coins counted in the target",
    "zainCashTitle": "Zain Cash",
    "zainTotalPay": "Total Pay",
    "zainIqd": "IQD",
    "zainName": "Name",
    "zainNameTips": "Input your name on your nation ID",
    "zainPhoneNumber": "Phone Number",
    "zainPhoneNumberTips": "Input your phone number",
    "zainSubmit": "Pay",
    "zainNumberErrorTips": "Please enter number or + sign",
    "zainNameNotNull": "Name cannot be empty",
    "luckyEventStartsIn": "Events Starts In",
    "luckyEventEndsIn": "Event Ends In",
    "luckyEventInstruction": "Event Gifts",
    "luckySendingFollowing": "Only the following gifts are counted in the event (Lucky Gift is 10%)",
    "luckyRoom": "Top Rooms",
    "luckyRoomTips": "Any Room Who Achieves More Than 500k Coins Will Get The Trophy Room Cover",
    "luckyGifter": "Supporters",
    "luckyAnchors": "Anchors",
    "luckyGoldenBoot": "Golden Boot",
    "luckyFootball": "Football",
    "luckyDefensiveMaster": "Defensive Master",
    "luckyTheWorldCup": "The WorldCup",
    "luckyNoOne": "No one on the list yet",
    "luckyRules": "Reward Details",
    "luckyHowTo": "Instructions",
    "luckyHowToGetI": "How to get i",
    "luckyTheGifts": "The Layla Football Event starts at 7pm 26th July and ends at 7pm 10th August Eg/Sa/Sr/Tr AST. Only the 4 selected new football related gifts are calculated in the events data. All top rooms over 500k coins will receive the trophy cover which is shown in the feature page. At the same time, top supporters and anchors will receive cash, diamonds, and target rewards shown below.",
    "luckyRewards": "Football Trophy Room Cover",
    "luckyRoomReward": "Top Supporters",
    "luckyGifterRewards": "Top Anchors",
    "luckyBlessingCard": "Event Rewards",
    "luckyTop_1": "300 USD Cash",
    "luckyTop_2": "200k Diamonds",
    "luckyTop_3": "100k Diamonds",
    "luckyTop_1_1": "200k Target",
    "luckyTop_2_1": "100k Target",
    "luckyTop_3_1": "50k Target",
    "luckyTop_4": "Exclusive Banner",
    "luckyEventEnd": "Event Ended",
    "anchorActive": {
        "activeList": "Active List",
        "activeListRewards": "Active List Rewards:",
        "back": "Back",
        "banner10": "Customized Banner (10 days)",
        "capture": "Capture Attention；Win Rewards",
        "charming": "Charming List Rewards:",
        "charmingList": "Charming List",
        "days": "Days",
        "entrance10": "Exclusive Entrance(10 days)",
        "entrance5": "Exclusive Entrance(5 days)",
        "entrance7": "Exclusive Entrance(7 days)",
        "frame10": "Exclusive avatar frame(10 days)",
        "frame5": "Exclusive avatar frame(5 days)",
        "frame7": "Exclusive avatar frame(7 days)",
        "hours": "Hours",
        "laseWeek": "Last Week",
        "laseWeekRank": "Last Week List",
        "listOne": "List:Active List",
        "listOneMsg1": "The points revenue earned by the host through 1v1=corresponding activity value (Including host replies, voice and video calls, and all points except for receiving gifts)",
        "listOneMsg2": "Voice room microphone duration/minute=10 points",
        "listTwo": "anking 2: Charming List",
        "listTwoMsg1": "1v1 Gift Diamond Quantity=Corresponding Charm Value",
        "listTwoMsg2": "Voice room gift diamonds = double charm value",
        "listTwoMsg3": "Lucky gifts count for 10% of charm value",
        "minutes": "Minutes",
        "noRanking": "No ranking data available",
        "points100000": "10k points",
        "points30k": "30k points",
        "points50000": "5k points",
        "points50k": "50k points",
        "rank": "Rank",
        "rewards": "Rewards",
        "rules": "Rules",
        "seconds": "Seconds",
        "top1": "TOP1",
        "top2": "TOP2",
        "top3": "TOP3"
    },
    "bindAccount": {
        "Bank_Transfer": "Bank Transfer",
        "Bind_Account": "Bind Account",
        "limit": {
            "account_name_tips": "Enter Full Name in English",
            "bank_card_number": "Enter Bank Account Number, e.g., 10000000000",
            "bankCode": "Enter Bank Swift Code, e.g., CMBCCNBS",
            "bankName": "Enter Bank Name in English, e.g., State Bank of India",
            "City": "Enter Your City in English",
            "country_code": "Enter Country Code in Uppercase, e.g., IN",
            "name_tips": "Enter First Name in English",
            "payoneer_email": "Find the Email Bind to Your Payoneer Account",
            "payoneerID": "Find Your Payoneer ID in Your Payoneer Account, e.g., 47289645466",
            "paypal_email": "Enter the Email bind to Your PayPal Account",
            "State_or_Province": "Enter State or Province in English",
            "Street": "Enter Street in English",
            "StrePostal_Codeet": "Enter Postal Code for the Address"
        },
        "Payment_Method": "Payment Method",
        "placeholder": {
            "bank_account_name": "Recipient Bank Account Name",
            "bank_card_number": "Recipient Bank Account Number",
            "bankCode": "Recipient Bank Code",
            "bankName": "Recipient Bank Name in English",
            "Beneficiary_country_code": "Recipient Country Code",
            "City": "Recipient Address - City",
            "country": "Bank Card Country Code",
            "Payee_First_name": "Recipient First Name",
            "Payee_Last_Name": "Recipient Last Name",
            "payoneer_email": "Enter your email",
            "payoneerID": "Enter your Payoneer ID",
            "paypal_email": "Enter your account email",
            "Postal_Code": "Recipient Address - Postal Code",
            "State_or_Province": "Recipient Address - State or Province",
            "Street": "Street"
        },
        "popup": {
            "Bound_account": "Bind Account:",
            "cancel": "Cancel",
            "complete": "Done",
            "confirm": "Confirm",
            "country": "Country of Bank Card",
            "payment_method": "Payment Method",
            "payoneer_email": "Payoneer Account Email"
        },
        "title": {
            "bank_account_name": "Recipient Bank Account Name",
            "bank_card_number": "Recipient Bank Account",
            "bankCode": "Bank Card Country Code",
            "bankName": "Recipient Bank Name",
            "country": "Country of Bank Card",
            "Payee_Address": "Recipient Address",
            "Payee_First_name": "Recipient First Name",
            "Payee_Last_Name": "Recipient Last Name",
            "payoneer_email": "Payoneer Account Email",
            "payoneerID": "Payoneer ID",
            "paypal_email": "PayPal Account Email"
        },
        "toast": {
            "bank_account_name": "Recipient’s Bank Account Name cannot be empty",
            "bank_card_number": "Recipient’s Bank Account Number cannot be empty",
            "bankCode": "Recipient Bank Code cannot be empty",
            "bankName": "Recipient Bank Name cannot be empty",
            "Beneficiary_country_code": "Recipient Country cannot be empty",
            "City": "Recipient City cannot be empty",
            "country": "Bank Card Country cannot be empty",
            "emailyz": "Please enter a valid and legal email address",
            "Payee_First_name": "Recipient First Name cannot be empty",
            "Payee_Last_Name": "Recipient Last Name cannot be empty",
            "payoneer_account": "Please double-check to avoid any financial loss",
            "payoneer_email": "Payoneer Email cannot be empty",
            "payoneerID": "Payoneer ID cannot be empty",
            "paypal_email": "Payoneer Email cannot be empty",
            "State_or_Province": "Recipient State or Province cannot be empty",
            "Street": "Recipient Street Address cannot be empty"
        }
    },
    "diamondDescription": {
        "title": "SUB&PSUB Rules",
        "txt1": "1. Both SUB and PSUB can be activated at the same time.",
        "txt2": "2. SUB users can claim free message vouchers daily.",
        "txt3": "3. PSUB users can claim free diamonds daily.",
        "txt4": "4. PSUB users can complete daily diamond return tasks.",
        "txt5": "5. Daily diamond return tasks for PSUB users and daily message vouchers for SUB users must be claimed on the same day they are issued, as they do not accumulate.",
        "txt6": "6. In-game purchases will not count towards the total number of diamonds spent."
    },
    "eventRewards": {
        "player": "Daily Active Players",
        "proportion": "Percentage of Reward if Room Wins",
        "title": "Event rewards",
        "txt1": "1. The data will be calculated after the day is finished. the day finished at 00:00 (UTC+8) China time.",
        "txt2": "2. Distribute rewards based on the number of active players and actual diamond consumption in the （count of active users- diamonds lost by users).",
        "txt3": "3. Calculate weekly data every Monday at 00:00 (UTC+8), with rewards to be issued within 3 days.",
        "txt4": "4. Reward distribution ratio:",
        "txt5": "5. Active player definition: a player who has bet more than 1,000 diamonds in total on that day in host room."
    },
    "failed": "The operation failed！",
    "idolHelp": {
        "content1": "Layla Idol is a special designation awarded by the Layla App to popular girls. Becoming a Layla Idol comes with various privileges, including a special badge, more exposure, and more matching messages.",
        "content2_1": "1. First, you must pass the real-person verification. Secondly, your chat score must reach 80 points.",
        "content2_2": "2. Meet the activity requirements on the day, and the chat score will be calculated at 4 AM (UTC+8) the next day based on the data from the past 7 days.",
        "content2_3": "3. If the activity requirements are not met on the day, the chat score will reset to 0 the next day. Becoming active again will restart the accumulation and calculation of the chat score.",
        "content2_4": "4. The daily activity requirements are as follows:",
        "content2_4_1": "a. More than ",
        "content2_4_2": "b. More than ",
        "content2_4_3": "c.Logged into the App the day before.",
        "content3_1": "The chat score is calculated based on your popularity in private chat scenarios. This includes:",
        "content3_2": "1. Exposure. How many people have seen you on the homepage, in Feed, or on your profile page.",
        "content3_3": "2. Online duration. How long you have stayed in the App.",
        "content3_4": "3. Active intimacy number. How many users with an intimacy greater than 3 have messaged you on the day.",
        "content3_5": "4. Message response rate. The percentage of your messages that received replies.",
        "content3_6": "5. Number of people you've initiated chats with. How many users you've greeted or messaged.",
        "content3_7": "6. Effective response rate. The rate at which you reply to others' messages within a valid time.",
        "content3_8": "7. Average chat diamond consumption. How many diamonds people have spent chatting with you, including chat and 1v1 gifts.",
        "content3_9": "8. Number of people who spent diamonds in chats. How many people have spent diamonds while chatting with you, including chat and 1v1 gifts.",
        "content4_1": "You can see the statistical values of your various data and compare them with those of Layla Idols on the chat score details page.",
        "content4_2": " Clicking on the tips for the corresponding items will guide you on how to improve those values.",
        "header1": "What is Layla Idol",
        "header2": "How to Become a Layla Idol",
        "header3": "What is a Chat Score",
        "header4": "How to Improve Your Chat Score",
        "minute": "minutes of online time over the past 7 days.",
        "people": "people actively chatted with over the past 7 days.",
        "title": "Layla Idol Help"
    },
    "inviteCash": {
        "COPY": "COPY",
        "Copy_successfully": "Copy successfully",
        "title": "Invite to earn money"
    },
    "Level": {
        "avatarFrame": "Avatar Frame",
        "badgeReward": "Badge Reward",
        "charmLevel": "Charm Level",
        "currentExp": "Current experience",
        "entranceEffect": "Entrance Effect",
        "familyLevel": "Family Level",
        "familyLeveldata": [
            "Level",
            "Required Contribution",
            "Members Number",
            "Identity"
        ],
        "gradePrivileges": "Grade Privileges",
        "identity": [
            "Deputy",
            "Elder"
        ],
        "personLevel": "Personal Level",
        "stillNeed": "Upgrade required",
        "title": "Level",
        "upgrade": "How to upgrade?",
        "upgradebox": {
            "charm": [
                {
                    "title": "Receiving gifts",
                    "value": "1 gold coins = 1 charm value"
                }
            ],
            "family": [
                {
                    "title": "Complete Family Tasks",
                    "value": "Get Family Contribution"
                }
            ],
            "person": [
                {
                    "title": "Send Gift",
                    "value": "1 Diamond = 1 Personal Exp"
                },
                {
                    "title": "Complete Tasks",
                    "value": "Get personal experience"
                }
            ],
            "wealth": [
                {
                    "title": "Send Gift",
                    "value": "1 Diamond = 1 Wealth Exp"
                },
                {
                    "title": "Complete Tasks",
                    "value": "Get wealth experience"
                }
            ]
        },
        "wealthLevel": "Wealth Level"
    },
    "nation": {
        "ae": "UAE",
        "ao": "Angola",
        "ar": "Argentina",
        "at": "Austria",
        "au": "Australia",
        "bd": "Bangladesh",
        "bh": "Bahrain",
        "br": "Brazil",
        "ca": "Canada",
        "cl": "Chile",
        "cn": "China",
        "de": "Germany",
        "dz": "Algeria",
        "eg": "Egypt",
        "es": "Spain",
        "fr": "France",
        "gb": "UK",
        "hk": "Hongkong",
        "il": "Israel",
        "iq": "Iraq",
        "ir": "Iran",
        "it": "Italia",
        "jo": "Jordan",
        "jp": "Japan",
        "kw": "Kuwait",
        "lb": "Lebanon",
        "lv": "Latvia",
        "ly": "Libya",
        "ma": "Morocco",
        "mx": "Mexico",
        "my": "Malaysia",
        "nl": "Netherlands",
        "om": "Oman",
        "ph": "Philippines",
        "pk": "Pakistan",
        "ps": "Pakistan",
        "pt": "Portugal",
        "qa": "Qatar",
        "ru": "Russia",
        "sa": "Saudi Arabia",
        "sd": "Sudan",
        "sy": "Syria",
        "tn": "Tunisia",
        "tr": "Turkey",
        "tw": "Taiwan",
        "us": "USA",
        "ye": "Yemen"
    },
    "nobilityLevel": {
        "diamond": "Diamond Consumption",
        "head": "Noble Level",
        "title": "Noble Rank Rules",
        "txt1": "1. Starting from 0:00 on the 1st of each natural month (UTC+8), the system will automatically calculate the user's Exp. points and re-match the user's noble level according to the Exp. points in the following table.",
        "txt2": "2. Each recharged diamond = 1 Exp. point.",
        "txt3": "3. Exp. points calculation = Current month Exp. points + Last month Exp. points.",
        "txt4": "I. After attaining the corresponding noble rank, the noble rank and privileges will be retained until the end of the next month. For example, if a user reaches Lv.4 on October 17th, Lv.4 privileges will continue until November 30th.",
        "txt5": "II. Within the current natural month period, if a user achieves a higher noble rank after obtaining a lower one, they will directly receive the higher-level noble rank, and the corresponding rank will be retained until the end of the next month. For example, if a user reaches Lv.2 on October 17th and then achieves Lv.4 on October 19th, they will directly receive Lv.4 privileges, which will last until November 30th. ",
        "txt6": "III. After the current natural month ends, the VIP level can retain what was achieved in the previous month, but the accumulation of VIP points will start from zero for the following month. For instance, if a user reaches Lv.4 in October, retains Lv.4 in November, and only reaches Lv.2 by the end of December, they will only enjoy Lv.2 privileges starting from December 1st.",
        "txt7": "Level",
        "txt8": "Required Exp. points",
        "txt9": "Required Exp. points from the previous level to this level"
    },
    "patricianDescription": [
        {
            "title": "1.What is patrician",
            "vlaue": [
                "Nobility is a symbol of users' noble status on the platform, and is currently divided into six levels.",
                "Nobles of different levels have corresponding privileges, and you can get nobles and enjoy privileges by paying for activation every month."
            ]
        },
        {
            "title": "2.Purchase rules",
            "vlaue": [
                "To obtain the privileges of Noble 1, 2, and 3, you can activate them by paying directly.",
                "As for nobles 4, 5, 6, you need to buy them with diamonds.",
                "After successful activation, the selected noble days will automatically increase by 31 days/month.",
                "If multiple nobles of different levels are activated at the same time, the remaining days of the higher level will be consumed first, and the days of the low-level nobles will remain unchanged."
            ]
        },
        {
            "title": "3.Renewal rules",
            "vlaue": [
                "After the activation is successful, if you renew within the validity period, you can enjoy the renewal discount. But if you reactivate after the expiration date, you will not enjoy the renewal discount.",
                "Activate any level of nobles, you can get the daily check-in diamond reward, you need to collect the reward yourself for this task, the expected collection will be regarded as automatic abandonment."
            ]
        }
    ],
    "patricianDescriptiontitle": "Patrician Description ",
    "raveParty": {
        "activityIntroduction1": " 🎉Congratulations, Layla has prepared an exclusive Carnival Party just for you.💫Complete recharge tasks and consumption tasks to earn corresponding points, which can be used to redeem items from the prize pool!🎁",
        "activityIntroduction2": "Additionally, the top 10 users in recharges and the top 10 users in consumption will receive extra privilege rewards.🏆There are plenty of rewards, so hurry and join in!💰✨",
        "benefits": "Unlock Exlusive Rewards & Benefits",
        "consumedEvery": " Consumed every 16,000 diamonds of lucky gifts will received 20 points.",
        "consumptionAmounts": "Consumption Amounts",
        "consumptionList": "Consumption List",
        "customizedAvatarFrame3": "customized avatar frame <br/>(3 days)",
        "customizedGift3": "customized gift <br/> (3 days)",
        "customizedLaunch15": "customized launch <br/>(15 days) ",
        "customizedLaunch3": "customized entrance <br/> (3 days) ",
        "customizedLaunch7": "customized banner <br/> (7 days) ",
        "details": "Details",
        "diamonds": "Diamonds",
        "duringTheEvent": "Ranking based on the number of lucky gift diamonds consumed during the event",
        "exchange": "Exchange",
        "leaderboardReward": "Leaderboard Reward",
        "leaderboardReward1": "1. During the event, users' recharges will be included in the event leaderboard. The top 10 users on the recharge leaderboard and Consumption leaderboard will receive extra privilege rewards. Please note that the validity period of the gift rewards is 7 days. Please use them within the validity period. ",
        "leaderboardReward2": "2. Please Note: 20 points will be awarded for every 2,100 diamonds recharged",
        "leaderboardReward3": "3. All rewards will be delivered to your account within 3~5 business days after the event ends.",
        "leaderboardReward4": "4. For the top ten of the two leaderboards, please add our contact information to claim the exlcusive rewards.",
        "luckyGiftsRules": "Lucky Gifts Rules",
        "luckyGiftsRules1": "1. Only lucky gifts will be included in the consumption list",
        "luckyGiftsRules2": "2. Send lucky gifts and have a chance to win the big reward.",
        "luckyGiftsRules3": "3. The more lucky gifts are given out in groups, the greater the chance of winning the jackpot.",
        "luckyGiftsRules4": "4. The prize is diamonds and will be sent to your bag.",
        "luckyGiftsRules5": "5. The multiple of the reward is the multiple of the unit price of the gift.",
        "luckyGiftsRules6": "6. Hosts only receive 10% of the gift value when receiving lucky gifts.",
        "luckyGiftsRules7": "7. Gifts with a four-leaf clover logo in the upper right corner are lucky gifts",
        "luckyGiftsRulesNote": "Notes:",
        "luckyGiftsRulesNote1": "1. The rewards received by users in the Lucky Gift activity can only be used on the Layla platform and may not be used for any profit-making activities.",
        "luckyGiftsRulesNote2": "2. Layla reserves the right of final interpretation",
        "luckyGiftsRulesTitle": "Rules:",
        "noRanking": "No ranking data available.",
        "ok": "OK",
        "points": "Points",
        "points1": "} points",
        "pointsReward": "Get Points to redeem exclusive rewards by recharging! Check the rewards in the Exchange Area below! Points will be given from the accumulative recharge that meets the corresponding number of diamonds during the event. ",
        "pointsReward2": "Please note the validity period of the redeemed rewards. The validity period of the reward starts counting from the moment of redemption.",
        "pointsReward3": "Please redeem before May.6th at 23:59 UTC+8. Otherwise, the points will expire.",
        "pointsRewardTitle": "Points Reward",
        "privilege": "Privilege",
        "rank": "Rank",
        "rankingBased": "Ranking based on the number of diamonds recharged during the event",
        "recharge": "Recharge",
        "rechargeList": "Recharge List",
        "redeemFailed": "Redeem Failed",
        "redeemSuccessful": "Redeem Successful",
        "remainingPoints": "Remaining Points",
        "rewards": "Rewards",
        "rules": "Rules",
        "telegram": "Telegram:",
        "telegramNum": "+852 6040 9424",
        "title": "Spring Carnival",
        "top1": "top1",
        "top2": "top2",
        "top3": "top3",
        "top46": "top4-6",
        "top710": "top7-10",
        "totalConsumption": "Total consumption of {",
        "tryAgain": "Insufficient redemption points, please confirm and try again",
        "WhatsApp": "Our WhatsApp number： ",
        "WhatsAppNumber": "+86 184 3591 9081 "
    },
    "transfer": {
        "contact_content1": "Recharge",
        "contact_content10": "Transfer record",
        "contact_content11": "Enter Amount",
        "contact_content12": "My Diamonds",
        "contact_content13": "Transfer failed, insufficient diamond balance",
        "contact_content14": "Set Transfer Password",
        "contact_content15": "Transfer Password",
        "contact_content16": "Please Enter Password",
        "contact_content17": "To avoid any issues with your transfers, remember this password",
        "contact_content18": "Confirmation password does not match the previously entered password, please try again",
        "contact_content19": "Confirm Transfer Password",
        "contact_content2": "Recharge Contact",
        "contact_content20": "Confirm",
        "contact_content21": "Your transfer feature is frozen, please contact the administrator",
        "contact_content22": "Transfer Confirmation",
        "contact_content23": "Transferor",
        "contact_content24": "Transfer Amount",
        "contact_content25": "Confirm Transfer",
        "contact_content26": "Forgot Password? Contact the administrator to reset it",
        "contact_content27": "Incorrect password, please try again",
        "contact_content28": "Transfer Successful",
        "contact_content29": "Transfer Contacts",
        "contact_content3": "Copy",
        "contact_content30": "No transfer contact records",
        "contact_content31": "No transfer records",
        "contact_content32": "Your transfer password has been set successfully, please remember it",
        "contact_content33": "Transfer",
        "contact_content34": "Diamond Details",
        "contact_content35": "Diamond Balance",
        "contact_content36": "Password must be at least four digits",
        "contact_content4": "Initiate Transfer",
        "contact_content5": "Enter Recipient's Account",
        "contact_content6": "Transferred diamonds will be immediately credited to the recipient's account and cannot be reversed; please proceed with caution",
        "contact_content7": "Enter ID",
        "contact_content8": "Next",
        "contact_content9": "ID entry error, user does not exist",
        "error": "Network connection failed, please try again",
        "finished": "All data loaded",
        "Loading": "Loading more data..."
    },
    "eventStartsIn": ""
}