export default{
    "wealthNeedNextLevel": "Deneyim bir sonraki seviyeye yükseltilebilir",
    "pkTouramentPro": "PK Tourament Pro",
    "pkTop1": "Top1",
    "pkTop2": "Top2",
    "pkTop3": "Top3",
    "pk1000Usd": "1000 USD",
    "pk500Usd": "500 USD",
    "pk300Usd": "300 USD",
    "pkRewards": "Rewards for each Qualifying Round",
    "pkRewards2": "Rewards for each Promotion Round",
    "pk50kCoins": "50k Coins Target",
    "pk100kCoins": "100k Coins Target",
    "pkNote": "Note: for qualifying round and promotion round rewards, if the diamonds spent in the specific PK is less than the reward amount. The reward will not be sent but the PK result is still counted",
    "pk200Usd": "200 USD",
    "pk30Day": "*30 Days",
    "pk15Day": "*15 Days",
    "pk7Day": "*7 Days",
    "pkNote2": "Note：Support in all PK Rounds is calculated. Lucky gifts are counted 10% Treasure Boxes are counted based on the gift won amount. ",
    "pkTourament": "Tourament",
    "pkToday": "Today Schedule",
    "pkSupporter": "Supporter",
    "pkQualifying": "Qualifying Round",
    "pkPromotion": "Promotion Round",
    "pkSummit": "Summit Series",
    "pk16Finals": "1/16-Finals",
    "pk8Finals": "1/8-Finals",
    "pkQuater": "Quater-Finals",
    "pkSemi": "Semi-Finals",
    "pkFinal": "Final",
    "pk3rd": "3rd Place",
    "dreamMidsummer": "Midsummer Night's Dream",
    "dreamSend": "Send exclusive gifts to seal your midsummer love",
    "dreamRank": "Sending the following event gifts during the event. The higher the ranking, the richer the prizes. Come and participate to get generous rewards!",
    "dreamPreview": "Rewards Preview",
    "dreamSupporter": "Supporter",
    "dreamHost": "Host",
    "dreamTop1": "Top1",
    "dreamTop2": "Top2",
    "dreamTop3": "Top3",
    "dreamTop4_10": "Top4-10",
    "dreamRankings": "Rankings",
    "dreamRose": "Rose Throne",
    "dreamRoseBasket": "Rose Basket",
    "dreamButterfly": "Butterfly Wonderland",
    "dreamLovers": "Everlasting Love",
    "dreamFlower": "How Much Love",
    "dream15d": "*15d",
    "dreamCentury": "Glorious Century",
    "dream7": "*7",
    "dream7d": "*7d",
    "dreamHeart": "Heart Flying",
    "dreamSummerLove": "Summer Love",
    "dreamFancy": "Fancy Unicorn",
    "dreamRules": "Event Rules",
    "dreamTime": "Event time",
    "dreamPlay": "How to play",
    "dreamUsersTop": "During the event, via sending specific gifts, users are ranked based on the number of diamonds given and coins received. Users with top rankings can receive rewards.",
    "dreamRewards": "Rewards for Rose Basket and Butterfly Wonderland Ranking",
    "dreamFrame": "Rose Throne Frame",
    "dreamCenturyGift": "Glorious Century Gift",
    "dreamEntrance": "Heart Flying Entrance",
    "dream5": "*5",
    "dream5d": "*5d",
    "dream3": "*3",
    "dream3d": "*3d",
    "dreamDiamonds": "Diamonds",
    "dream25000": "*25000",
    "dreamVerification": "Summer Love Verification",
    "dreamUnicorn": "Fancy Unicorn",
    "dreamEverlasting": "Rewards for Everlasting Love and How Much Love Rank",
    "dreamCoins": "Coins",
    "dream100000": "*100000",
    "dream50000": "*50000",
    "dream5000": "*5000",
    "dreamHostBottom": "Coins counted in the target",
    "zainCashTitle": "Zain Cash",
    "zainTotalPay": "Toplam Ödeme",
    "zainIqd": "IQD",
    "zainName": "Adı",
    "zainNameTips": "Kimlikteki adınızı girin",
    "zainPhoneNumber": "Telefon Numarası",
    "zainPhoneNumberTips": "Telefon numaranızı girin",
    "zainSubmit": "Öde",
    "zainNumberErrorTips": "Lütfen numara veya + işareti girin",
    "zainNameNotNull": "İsim boş bırakılamaz",
    "luckyEventStartsIn": "Etkinlik Başlıyor",
    "luckyEventEndsIn": "",
    "luckyEventInstruction": "Etkinlik Talimatları",
    "luckySendingFollowing": "Aşağıdaki hediyeyi göndermek küçük koyun kazanabilir",
    "luckyRoom": "Oda",
    "luckyRoomTips": "",
    "luckyGifter": "Bağışçı",
    "luckyAnchors": "",
    "luckyGoldenBoot": "",
    "luckyFootball": "",
    "luckyDefensiveMaster": "",
    "luckyTheWorldCup": "",
    "luckyNoOne": "Henüz listede kimse yok~",
    "luckyRules": "Kurallar",
    "luckyHowTo": "Sıralamaya nasıl girilir?",
    "luckyHowToGetI": "Nasıl elde edilir",
    "luckyTheGifts": "Aktivite sırasında verilen hediyeler elmas sayısına göre sıralanır, etkinlikten alınan hediyeler altın paraların sayısına göre sıralanır.",
    "luckyRewards": "Ödüller",
    "luckyRoomReward": "Oda Ödülü",
    "luckyGifterRewards": "Bağışçı Ödülleri",
    "luckyBlessingCard": "Kutsama Kartı",
    "luckyTop_1": "Kredi 100000",
    "luckyTop_2": "Avatar Çerçevesi x<span>10<span>gün",
    "luckyTop_3": "Giriş x10gün",
    "luckyTop_1_1": "",
    "luckyTop_2_1": "",
    "luckyTop_3_1": "",
    "luckyTop_4": "Özel Afiş",
    "luckyEventEnd": "Etkinlik Sonu",
    "anchorActive": {
        "activeList": "Aktif Liste",
        "activeListRewards": "Aktif Liste Ödülleri:",
        "back": "Geri",
        "banner10": "Özelleştirilmiş Afiş (10 gün)",
        "capture": "Dikkat Çekin; Ödülleri Kazanın",
        "charming": "Cazibe Listesi Ödülleri:",
        "charmingList": "Cazibe Listesi",
        "days": "Günler",
        "entrance10": "Özel Giriş (10 gün)",
        "entrance5": "Özel Giriş (5 gün)",
        "entrance7": "Özel Giriş (7 gün)",
        "frame10": "Özel avatar çerçevesi (10 gün)",
        "frame5": "Özel avatar çerçevesi (5 gün)",
        "frame7": "Özel avatar çerçevesi (7 gün)",
        "hours": "Saatler",
        "laseWeek": "Geçen Hafta",
        "laseWeekRank": "Geçen Hafta Listesi",
        "listOne": "Liste: Aktif Liste",
        "listOneMsg1": "Sunucunun 1v1'den kazandığı puan geliri = karşılık gelen etkinlik değeri (Sunucu yanıtları, sesli  görüntülü aramalar,  hediye alma hariç tüm puanlar dahil)",
        "listOneMsg2": "ses odası mikrofonunun süresi / dakikası = 10 puan .",
        "listTwo": "Sıralama 2: Cazibe Listesi",
        "listTwoMsg1": "1v1 Hediye Elmas Miktarı=Karşılık Gelen Çekicilik Değeri",
        "listTwoMsg2": "Sesli oda hediye elmasları = çift çekicilik değeri",
        "listTwoMsg3": "Şanslı hediyeler çekicilik değerinin %10'u sayılır",
        "minutes": "Dakika",
        "noRanking": "Sıralama verisi yok",
        "points100000": "10k puan",
        "points30k": "30k puan",
        "points50000": "5k puan",
        "points50k": "50k puan",
        "rank": "Sıralama",
        "rewards": "Ödüller",
        "rules": "Kurallar",
        "seconds": "Saniye",
        "top1": "TOP1",
        "top2": "TOP2",
        "top3": "TOP3"
    },
    "bindAccount": {
        "Bank_Transfer": "Banka Havalesi",
        "Bind_Account": "Hesap Bağlama",
        "limit": {
            "account_name_tips": "Adınızı İngilizce olarak girin",
            "bank_card_number": "Banka Hesap Numarasını girin, örneğin: 10000000000",
            "bankCode": "Banka Swift Kodunu girin, örneğin: CMBCCNBS",
            "bankName": "Banka Adını İngilizce olarak girin, örneğin: State Bank of India",
            "City": "Şehrinizi İngilizce olarak girin",
            "country_code": "Ülke Kodunu büyük harflerle girin, örneğin: IN",
            "name_tips": "Adınızı İngilizce olarak girin",
            "payoneer_email": "Payoneer Hesabınıza bağlı olan e-postayı bulun",
            "payoneerID": "Payoneer Hesabınızda Payoneer Kimliğinizi bulun, örneğin: 47289645466",
            "paypal_email": "PayPal Hesabınıza bağlı olan e-postayı girin",
            "State_or_Province": "Şehir veya Bölgeyi İngilizce olarak girin",
            "Street": "Sokağı İngilizce olarak girin",
            "StrePostal_Codeet": "Adresin Posta Kodunu girin"
        },
        "Payment_Method": "Ödeme Yöntemi",
        "placeholder": {
            "bank_account_name": "Alıcının Banka Hesap Adı",
            "bank_card_number": "Alıcının Banka Hesap Numarası",
            "bankCode": "Alıcı Banka Kodu",
            "bankName": "Alıcı Banka Adı İngilizce",
            "Beneficiary_country_code": "Alıcı Ülke Kodu",
            "City": "Alıcı Adresi - Şehir",
            "country": "Banka Kartı Ülke Kodu",
            "Payee_First_name": "Alıcının Adı",
            "Payee_Last_Name": "Alıcının Soyadı",
            "payoneer_email": "E-postanızı girin",
            "payoneerID": "Payoneer kimliğinizi girin",
            "paypal_email": "Hesap e-postanızı girin",
            "Postal_Code": "Alıcı Adresi - Posta Kodu",
            "State_or_Province": "Alıcı Adresi - Eyalet veya Bölge",
            "Street": "Sokak"
        },
        "popup": {
            "Bound_account": "Hesap Bağla:",
            "cancel": "İptal",
            "complete": "Tamamlandı",
            "confirm": "Onayla",
            "country": "Banka Kartı Ülkesi",
            "payment_method": "Ödeme Yöntemi",
            "payoneer_email": "Payoneer Hesap E-postası"
        },
        "title": {
            "bank_account_name": "Alıcının Banka Hesap Adı",
            "bank_card_number": "Alıcının Banka Hesabı",
            "bankCode": "Banka Kartı Ülke Kodu",
            "bankName": "Alıcının Banka Adı",
            "country": "Banka Kartı Ülkesi",
            "Payee_Address": "Alıcının Adresi",
            "Payee_First_name": "Alıcının Adı",
            "Payee_Last_Name": "Alıcının Soyadı",
            "payoneer_email": "Payoneer Hesap E-Postası",
            "payoneerID": "Payoneer Id",
            "paypal_email": "PayPal Hesap E-postası"
        },
        "toast": {
            "bank_account_name": "Alıcının Banka Hesap Adı boş bırakılamaz",
            "bank_card_number": "Alıcının Banka Hesap Numarası boş bırakılamaz",
            "bankCode": "Alıcı Banka Kodu boş bırakılamaz",
            "bankName": "Alıcı Banka Adı boş bırakılamaz",
            "Beneficiary_country_code": "Alıcı Ülkesi boş bırakılamaz",
            "City": "Alıcı Şehri boş bırakılamaz",
            "country": "Banka Kartı Ülkesi boş bırakılamaz",
            "emailyz": "Lütfen geçerli ve yasal bir e-posta adresi girin",
            "Payee_First_name": "Alıcının Adı boş bırakılamaz",
            "Payee_Last_Name": "Alıcının Soyadı boş bırakılamaz",
            "payoneer_account": "Herhangi bir maddi kayıptan kaçınmak için lütfen tekrar kontrol edin",
            "payoneer_email": "Payoneer E-postası boş bırakılamaz",
            "payoneerID": "Payoneer Kimliği boş bırakılamaz",
            "paypal_email": "Payoneer E-postası boş bırakılamaz",
            "State_or_Province": "Alıcının Eyaleti veya İli boş bırakılamaz",
            "Street": "Alıcının Sokak Adresi boş bırakılamaz"
        }
    },
    "diamondDescription": {
        "title": "VIP ve SVIP kuralları",
        "txt1": "1. VIP ve SVIP aynı anda etkinleştirilebilir.",
        "txt2": "2. VIP kullanıcıları günlük ücretsiz mesaj kuponları alabilirler.",
        "txt3": "3. SVIP kullanıcıları günlük ücretsiz elmaslar alabilirler.",
        "txt4": "4. SVIP kullanıcıları günlük elmas iade görevlerini tamamlayabilirler.",
        "txt5": "5. SVIP elmas iade görevleri günlük olarak güncellenir ve aynı gün talep edilmelidir. VIP günlük mesaj kuponları da aynı gün talep edilmelidir; talep edilmezse birikmez ve süresi dolabilir.",
        "txt6": "6. Oyun harcamaları, elmas tüketimi miktarının içine dahil edilmeyecektir."
    },
    "eventRewards": {
        "player": "Günlük aktif oyuncu sayısı",
        "proportion": "Oda kazanırsa alınacak yüzde",
        "title": "Etkinlik Ödülleri",
        "txt1": "Datalar gün bittikten sonra hesaplanacaktır. Gün Çin saatine göre saat 00:00 (UTC+8) da biter",
        "txt2": "2. Ödülleri aktif oyuncu sayısına ve odadaki gerçek elmas tüketimine (kullanıcıların bahis yaptığı elmaslar - kullanıcıların kazandığı elmaslar) dayalı olarak dağıtın.",
        "txt3": "3. Haftalık verileri her Pazartesi saat 00:00'da (UTC+8 saat diliminde) hesaplayın ve ödüllerin 3 gün içinde verilmesini sağlayın.",
        "txt4": "4. Ödül dağıtım oranı:",
        "txt5": "5. Aktif oyuncu tanımı: Bir oyuncu günlük toplamda 1.000 elmas üzerinden bahis yapmış olan oyuncudur."
    },
    "failed": "Operasyon başarısız",
    "idolHelp": {
        "content1": "Layla Idol, Layla Uygulaması tarafından popüler kızlara verilen özel bir unvandır. Layla Idol olmak, özel bir rozet, daha fazla maruz kalma ve daha fazla eşleşen mesaj gibi çeşitli ayrıcalıklarla gelir.",
        "content2_1": "1. Öncelikle, gerçek kişi doğrulamasını geçmelisiniz. İkincisi, sohbet puanınızın 80 puana ulaşması gerekir.",
        "content2_2": "2. Gün içinde aktivite gereksinimlerini karşılayın ve sohbet puanı, geçmiş 7 günün verilerine dayanarak ertesi gün saat 4'te (UTC+8) hesaplanacaktır.",
        "content2_3": "3. Eğer gün içinde aktivite gereksinimleri karşılanmazsa, sohbet puanı ertesi gün sıfıra sıfırlanır. Tekrar aktif hale gelmek, sohbet puanının birikimini ve hesaplamasını yeniden başlatacaktır.",
        "content2_4": "4. Günlük aktivite gereksinimleri şunlardır:",
        "content2_4_1": "a.Daha fazla",
        "content2_4_2": "b.Daha fazla",
        "content2_4_3": "c.Bir önceki gün uygulamaya giriş yapılmış olması.",
        "content3_1": "Sohbet puanı, özel sohbet senaryolarındaki popülerliğinize dayanarak hesaplanır. Bu, şunları içerir:",
        "content3_2": "1. Maruz Kalma. Ana sayfada, Feed'de veya profil sayfanızda sizi kaç kişinin gördüğü.",
        "content3_3": "2. Çevrimiçi Süre. Uygulamada ne kadar süre kaldığınız.",
        "content3_4": "3. Aktif Yakınlık Sayısı. O gün içinde 3'ten fazla yakınlığa sahip kaç kullanıcının size mesaj atmış",
        "content3_5": "4. Mesaj Yanıt Oranı. Mesajlarınızın kaçının yanıt aldığı yüzdesi.",
        "content3_6": "5. Sohbet başlatılan kişi sayısı. Kaç kullanıcıyı selamladığınız veya mesaj attığınız.",
        "content3_7": "6. Etkili Yanıt Oranı. Başkalarının mesajlarına geçerli bir süre içinde verdiğiniz yanıt oranı.",
        "content3_8": "7. Ortalama Sohbet Elmas Tüketimi. Sohbet ederken veya 1'e 1 hediyelerle kaç elmas harcanmış",
        "content3_9": "8. Sohbetlerde Elmas Harcayan Kişi Sayısı. Sohbet ederken veya 1'e 1 hediyelerle elmas harcayan kaç kişi olduğu.",
        "content4_1": "Çeşitli verilerinizin istatistiksel değerlerini görebilir ve bunları sohbet puanı detayları sayfasında Layla Idol'lerinkiyle karşılaştırabilirsiniz.",
        "content4_2": "İlgili öğeler için ipuçlarına tıklayarak bu değerleri nasıl iyileştireceğinize dair rehberlik alabilirsiniz.",
        "header1": "Layla Idol Nedir",
        "header2": "Layla Idol Nasıl Olunur",
        "header3": "Sohbet Puanı Nedir",
        "header4": "Sohbet Puanınızı Nasıl İyileştirebilirsiniz",
        "minute": "Son 7 günde  çevrimiçi süre olduğu dakika",
        "people": "7 günder fazladır aktif bir şekilde sohbet edilmiş kişiler",
        "title": "Layla Idol Yardım"
    },
    "inviteCash": {
        "COPY": "Kopyala",
        "Copy_successfully": "Başarıyla kopyalandı",
        "title": "Para kazanmak için davet et"
    },
    "Level": {
        "avatarFrame": "avatar çerçevesi",
        "badgeReward": "Rozet Ödülü",
        "charmLevel": "Çekicilik",
        "currentExp": "Mevcut deneyim",
        "entranceEffect": "giriG efekti",
        "familyLevel": "Aile seviyesi",
        "familyLeveldata": [
            "Seviye",
            "gerekli katkı",
            "Üye sayısı",
            "kimlik"
        ],
        "gradePrivileges": "Seviye ayrıcalığı",
        "identity": [
            "Aile reisi yardımcısı",
            "Daha büyük"
        ],
        "personLevel": "Kişisel Seviye",
        "stillNeed": "Ihtiyaç",
        "title": "Seviye",
        "upgrade": "Nasıl yükseltilir?",
        "upgradebox": {
            "charm": [
                {
                    "title": "Hediye vermek ",
                    "value": "1 altın para = 1 çekicilik değer"
                }
            ],
            "family": [
                {
                    "title": "Aile Görevlerini Tamamlayın",
                    "value": "Aile katkısı alın"
                }
            ],
            "person": [
                {
                    "title": "Hediye ver",
                    "value": "1 elmas = 1 kişisel deneyim"
                },
                {
                    "title": "Görevleri Tamamla",
                    "value": "Kişisel deneyim al"
                }
            ],
            "wealth": [
                {
                    "title": "Hediye ver",
                    "value": "1 elmas = 1 zenginlik deneyimi"
                },
                {
                    "title": "Görevleri Tamamla",
                    "value": "Kişisel deneyim al"
                }
            ]
        },
        "wealthLevel": "Servet"
    },
    "nation": {
        "ae": "Birleşik Arap Emirlikleri",
        "ao": "Angola",
        "ar": "Arjantin",
        "at": "Avusturya",
        "au": "Avustralya",
        "bd": "Bangladeş",
        "bh": "Bahreyn",
        "br": "Brezilya",
        "ca": "Kanada",
        "cl": "Şili",
        "cn": "Çin",
        "de": "Almanya",
        "dz": "Cezayir",
        "eg": "Mısır",
        "es": "ispanya",
        "fr": "Fransa",
        "gb": "İngiltere",
        "hk": "Çin Hong Kong",
        "il": "İsrail",
        "iq": "Irak",
        "ir": "İran",
        "it": "İtalya",
        "jo": "Ürdün",
        "jp": "Japonya",
        "kw": "Kuveyt",
        "lb": "Lübnan",
        "lv": "Letonya",
        "ly": "Libya",
        "ma": "Fas",
        "mx": "Meksika",
        "my": "Malezya",
        "nl": "Hollanda",
        "om": "Umman",
        "ph": "Filipinler",
        "pk": "Pakistan",
        "ps": "Filistin",
        "pt": "Portekiz",
        "qa": "Katar",
        "ru": "Rusya",
        "sa": "Suudi Arabistan",
        "sd": "Sudan",
        "sy": "Suriye",
        "tn": "Tunus",
        "tr": "Türkiye",
        "tw": "Tayvan, Çin",
        "us": "Amerika Birleşik Devletleri",
        "ye": "Yemen"
    },
    "nobilityLevel": {
        "diamond": "Elmas Tüketimi",
        "head": "Soylu Seviye",
        "title": "Asil Derece Kuralları",
        "txt1": "1. Her doğal ayın 1'inde (UTC+8) saat 0:00'dan itibaren sistem otomatik olarak kullanıcının Deneyim puanlarını hesaplayacak ve kullanıcının asil seviyesini aşağıdaki tabloda yer alan Deneyim puanlarına göre yeniden eşleştirecektir.",
        "txt2": "2. Her yeniden yüklenen elmas = 1 Deneyim puanı.",
        "txt3": "3. Deneyim puanı hesaplaması = Mevcut ay Deneyim puanları + Geçen ay Deneyim puanları.",
        "txt4": "I. Karşılık gelen asil dereceye ulaştıktan sonra, asil derece ve ayrıcalıklar bir sonraki ayın sonuna kadar korunur. Örneğin, bir kullanıcı 17 Ekim'de Lv.4'e ulaşırsa, Lv.4 derecesi 30 Kasım'a kadar devam eder.",
        "txt5": "II. Mevcut doğal ay içinde, bir kullanıcı daha düşük bir asil derece elde ettikten sonra daha yüksek bir asil dereceye ulaşırsa, doğrudan daha yüksek seviyeli asil dereceyi alır ve karşılık gelen derece bir sonraki ayın sonuna kadar devam eder. Örneğin, bir kullanıcı 17 Ekim'de Lv.2'ye ulaşır ve ardından 19 Ekim'de Lv.4'e ulaşırsa, doğrudan Lv.4 ayrıcalıklarını alır ve bu ayrıcalıklar 30 Kasım'a kadar sürer.",
        "txt6": "III. Mevcut  ay sona erdikten sonra, VIP seviyesi önceki ayda elde edilenleri koruyabilir, ancak bir sonraki ay için VIP puanlarının bir sonraki sıfırdan başlatılmasını bekler. Örneğin, bir kullanıcı Ekim ayında Lv.4'e ulaşır, Kasım ayında Lv.4'ü korur ve Aralık sonuna kadar sadece Lv.2'ye ulaşırsa, 1 Aralık'tan itibaren yalnızca Lv.2 ayrıcalıklarını keyif alır.",
        "txt7": "Seviye",
        "txt8": "Gerekli Deneyim puanları",
        "txt9": "Önceki seviyeden bu seviyeye kadar gereken Deneyim puanları"
    },
    "patricianDescription": [
        {
            "title": "soylu nedir",
            "vlaue": [
                "\"soylu, platformdaki kullanıcıların asil statüsünün bir simgesidir ve şu anda altı seviyeye ayrılmıştır.",
                " Farklı düzeylerde soyluların karşılık gelen farklı ayrıcalıkları vardır ve aylık abonelik ödeyerek soyluluk alabilir ve ayrıcalıklardan yararlanabilirsiniz."
            ]
        },
        {
            "title": "2. Satın alma kuralları",
            "vlaue": [
                "Soylu 1, 2 ve 3'ün ayrıcalıklarını alın ve doğrudan ödeyerek etkinleştirin",
                "Soylu 4, 5 ve 6'nın etkinleştirilmesi için elmaslarla satın alınması gerekir.",
                "Etkinleştirme başarılı olduktan sonra, seçilen asil günlerin sayısı otomatik olarak ayda 31 gün olarak artacaktır.",
                "rdeki birden fazla Soylu aynı anda etkinleştirilirse, önce kalan günü daha yüksek level  tüketilecek ve daha düşük seviyedeki soyluların günleri değişmeden kalacaktır."
            ]
        },
        {
            "title": "3. Yenileme Kuralları",
            "vlaue": [
                "Başarılı aktivasyondan sonra, geçerlilik süresi içinde yenilerseniz yenileme indiriminden yararlanabilirsiniz. Ancak geçerlilik süresinden sonra yeniden etkinleştirirseniz, yenileme indiriminden yararlanamazsınız.。",
                "Herhangi bir seviyedeki soyluları etkinleştirin, günlük giriş elmas ödülünü alabilirsiniz, bu görev için ödülü kendiniz toplamanız gerekir, beklenen koleksiyon otomatik olarak vazgeçme olarak kabul edilecektir."
            ]
        }
    ],
    "patricianDescriptiontitle": "Soylu açıklaması",
    "raveParty": {
        "activityIntroduction1": "🎉Tebrikler, Layla sadece sizin için özel bir Karnaval Partisi hazırladı.💫Ödül havuzundan öğeleri kurtarmak için kullanılabilecek karşılık gelen puanları kazanmak için yükleme görevlerini ve tüketim görevlerini tamamlayın! 🎁",
        "activityIntroduction2": "Ek olarak, yeniden yükleme yapan ilk 10 kullanıcı ve tüketimdeki ilk 10 kullanıcı ekstra ayrıcalık ödülleri alacak.🏆Çok fazla ödül var, bu yüzden acele edin ve katılın! 💰✨",
        "benefits": "Ekstra ödüllerin ve yararlanımların kilidini aç",
        "consumedEvery": "Tüketilen her 16.000 elmas şanslı hediye 20 puan alacak.",
        "consumptionAmounts": "Tüketim Miktarları",
        "consumptionList": "Kullanım Listesi",
        "customizedAvatarFrame3": "customized avatar frame <br/>(3 gün)",
        "customizedGift3": "Özel hediye <br/>(3 gün)",
        "customizedLaunch15": "Özel başlatma <br/> (15 gün) ",
        "customizedLaunch3": "Özel giriş <br/> (3 gün)",
        "customizedLaunch7": "özelleştirilmiş afiş <br/> (7 days)",
        "details": "Ayrıntılar",
        "diamonds": "Elmas",
        "duringTheEvent": "Sıralama, etkinlik sırasında harcanan şanslı hediyelere dayanıyor",
        "exchange": "Bozdurma",
        "leaderboardReward": "Lİderlik ödülü",
        "leaderboardReward1": "1. Etkinlik sırasında, kullanıcıların yüklemeleri etkinlik sıralamasına dahil edilecektir. Yükleme skor tablosunda ve Tüketim skor tablosundaki ilk 10 kullanıcı ekstra ayrıcalık ödülleri alacak. Hediye ödüllerinin geçerlilik süresinin 7 gün olduğunu lütfen unutmayın. Lütfen bunları geçerlilik süresi içinde kullanın.",
        "leaderboardReward2": "2. Lütfen Dikkat: yükeleme yapılan her 2.100 elmas için 20 puan hediye verilecektir",
        "leaderboardReward3": "3. Tüm ödüller, etkinlik sona erdikten sonra 3 ~ 5 iş günü içinde hesabınıza teslim edilecektir.",
        "leaderboardReward4": "4. İki liderlik tablosunda ilk on için, ekstra ödülleri talep etmek için lütfen iletişim bilgilerinizi ekleyin.",
        "luckyGiftsRules": "Şanslı Hediye Kuralları",
        "luckyGiftsRules1": "1. Tüketim listesine sadece şanslı hediyeler dahil edilecektir.",
        "luckyGiftsRules2": "2. Şanslı hediyeler gönderin ve büyük ödülü kazanma şansınız olsun.",
        "luckyGiftsRules3": "3. Gruplar halinde ne kadar şanslı hediyeler verilirse, ikramiyeyi kazanma şansı o kadar artar.",
        "luckyGiftsRules4": "4. Ödül elmaslardır ve çantanıza gönderilecektir.",
        "luckyGiftsRules5": "5. Ödülün kazancı, hediyenin birim fiyatı kadardır.",
        "luckyGiftsRules6": "6. Yayıncılar, şanslı hediyeler alırken hediye değerinin yalnızca% 10 'unu alır.",
        "luckyGiftsRules7": "7. Sağ üst köşede dört yapraklı yonca logolu hediyeler şanslı hediyelerdir.",
        "luckyGiftsRulesNote": "Notlar:",
        "luckyGiftsRulesNote1": "1. Şanslı Hediye etkinliğinde kullanıcıların aldığı ödüller yalnızca Layla platformunda kullanılabilir ve herhangi bir kar elde etme etkinliği için kullanılamaz.",
        "luckyGiftsRulesNote2": "2. Layla son yorum hakkını saklı tutar ",
        "luckyGiftsRulesTitle": "Kurallar:",
        "noRanking": "Sıralama verisi yok.",
        "ok": "OK",
        "points": "Puanlar",
        "points1": "} puan",
        "pointsReward": "Yükleme yaparak özel ödülleri kullanmak için Puan Alın! Aşağıdaki Bozdurma Alanındaki ödülleri kontrol edin! Etkinlik sırasında karşılık gelen sayıda elması karşılayan toplam yüklemeden puan verilecektir. ",
        "pointsReward2": "Lütfen yukarıda belirtilen ödüllerin geçerlilik süresine dikkat edin. Ödülün geçerlilik süresi, kullanım anından itibaren saymaya başlar.",
        "pointsReward3": "Lütfen 6 Mayıs 23: 59 UTC + 8 'den önce kullanın. Aksi takdirde puanların süresi dolacaktır.",
        "pointsRewardTitle": "Puan Ödülleri ",
        "privilege": "Ayrıcalık",
        "rank": "Sıralama",
        "rankingBased": "Sıralama, etkinlik sırasında yapılan elmas yüklemelerine dayanır",
        "recharge": "Yükleme",
        "rechargeList": "Yükleme Listesi",
        "redeemFailed": "Dönüşüm  Başarısız",
        "redeemSuccessful": "Dönüşüm Başarılı ",
        "remainingPoints": "Kalan noktalar",
        "rewards": "Ödüller",
        "rules": "Kurallar",
        "telegram": "Telegram:",
        "telegramNum": "+852 6040 9424",
        "title": "Bahar Karnavalı",
        "top1": "top1",
        "top2": "top2",
        "top3": "top3",
        "top46": "top4-6",
        "top710": "top7-10",
        "totalConsumption": "Toplam tüketim {",
        "tryAgain": "Yetersiz dönüşüm puanı, lütfen doğrulayın ve tekrar deneyin",
        "WhatsApp": "WhatsApp numaramız:",
        "WhatsAppNumber": "+ 86 184 3591 9081"
    },
    "transfer": {
        "contact_content1": "Yükleme",
        "contact_content10": "Transfer kaydı",
        "contact_content11": "Miktar girin",
        "contact_content12": "Elmaslarım",
        "contact_content13": "Transfer başarısız, yetersiz elmas bakiyesi",
        "contact_content14": "Transfer Şifresi Ayarla",
        "contact_content15": "Transfer Şifresi",
        "contact_content16": "Lütfen Şifreyi Girin",
        "contact_content17": "Transferlerinizde herhangi bir sorun yaşamamak için bu şifreyi hatırlayın",
        "contact_content18": "Onay şifresi önce girilen şifreyle uyuşmuyor, lütfen tekrar deneyin",
        "contact_content19": "Transfer Şifresini Onayla",
        "contact_content2": "Yenileme İletişimi",
        "contact_content20": "Onayla",
        "contact_content21": "Transfer özelliğiniz donduruldu, lütfen yöneticiyle iletişime geçin",
        "contact_content22": "Transfer Onayı",
        "contact_content23": "Transfer eden",
        "contact_content24": "Transfer Miktarı",
        "contact_content25": "Transferi Onayla",
        "contact_content26": "Şifrenizi mi unuttunuz? Sıfırlamak için yöneticiyle iletişime geçin",
        "contact_content27": "Yanlış şifre, lütfen tekrar deneyin",
        "contact_content28": "Transfer Başarılı",
        "contact_content29": "Transfer Kişileri",
        "contact_content3": "Kopyala",
        "contact_content30": "Transfer kişi kaydı yok",
        "contact_content31": "Transfer kaydı yok",
        "contact_content32": "Transfer şifreniz başarıyla ayarlandı, lütfen hatırlayın",
        "contact_content33": "Transfer",
        "contact_content34": "Elmas Ayrıntıları",
        "contact_content35": "Elmas Bakiyesi",
        "contact_content36": "Şifre en az dört haneli olmalıdır",
        "contact_content4": "Transferi Başlat",
        "contact_content5": "Alıcının Hesabını Girin",
        "contact_content6": "Transfer edilen elmaslar alıcının hesabına hemen yatırılacak ve geri alınamayacaktır; lütfen dikkatli ilerleyin",
        "contact_content7": "Kimlik Girin",
        "contact_content8": "Sonraki",
        "contact_content9": "Kimlik girişi hatası, kullanıcı mevcut değil",
        "error": "Ağ bağlantısı başarısız, lütfen tekrar deneyin",
        "finished": "Tüm veriler yüklendi",
        "Loading": "Daha fazla veri yükleniyor..."
    },
    "eventStartsIn": ""
}